<template>
  <main>
    <!-- application form account-info change -->
    <section id="register_points_search_payment">
      <div class="w1300 layout_container">

        <!-- 表單主標題 -->
        <div class="main_title_box">
          <h3 class="main_title txt-bold">積分申請繳費</h3>
          <div class="img_box">
            <img src="@/statics/img/index/icon_title2.svg" alt="">
          </div>
        </div>

        <div class="main_description_box mg-b-50">
          <h4 class="main_description_title txt-light_green txt-bold">繳費說明</h4>
          <ul class="number_list mg-b-10">
            <li><p>行政處理費用計費方式：1-8小時活動1000元，9-16小時活動2000元，以此類推。</p></li>
            <li><p>如有其他問題，請聯絡台灣居家醫療醫學會秘書處。</p></li>
          </ul>
          <p>聯絡電話：{{ contact_info.tel }}</p>
          <p>電子郵件：{{ contact_info.email }}</p>
        </div>

        <div class="main_description_box mg-b-50">
          <p>申請單位：{{ applyHost }}</p>
          <p>活動主題：{{ applyTopic }}</p>
          <p>活動時間：{{ applyStart }} ~ {{ applyEnd }}</p>
          <p>時數：{{ applyHour }}</p>
        </div>

        <!-- table area -->
        <div class="table_box">
          <table class="txt-center">
            <thead>
              <tr>
                <th class="td-main txt-left"><p>{{ th1 }}</p></th>
                <!-- <th class="min100"><p>{{ th2 }}</p></th> -->
                <th class="min130"><p>{{ th3 }}</p></th>
              </tr>
            </thead>
            <tbody id="table_data">

              <tr v-for="item in applyOrders" :key="item.uuid">
                <td :data-title="th1" class="td-main txt-left">{{ item.content }}</td>
                <!-- <td :data-title="th2"><p>{{ eventPrice }}</p></td> -->
                <td :data-title="th3">{{ item.total }}</td>
              </tr>

            </tbody>
          </table>
        </div>

        <div class="form_box">
          <form>
            <div class="form_interactive">
              <div class="fixed_info w50">
                <p class="fixed_info_title">付款方式</p>
                <p class="fixed_info_content">信用卡</p>
              </div>
              <div class="fixed_info w50">
                <p class="fixed_info_title">總金額</p>
                <p class="fixed_info_content">{{ applyAmount }}</p>
              </div>
            </div>
          </form>

          <!-- 綠界金流按鈕 -->
          <div v-html="resHtml"></div>
          <div class="errMsg txt-center txt-red">
            <p class="h3">{{ paymentErrorMsg }}</p>
          </div>
        </div>

      </div>
    </section>
  </main>
</template>
<script>
import { mapActions } from 'vuex';
import { eventsApplyView, orderPay } from '@/lib/http';
import _ from 'lodash';

export default {
  name: 'RegisterPointSearchConfirm',
  data() {
    return {
      th1: '費用名稱',
      th2: '收據抬頭',
      th3: '金額',
      applyID: '',
      applyInfo: {},
      contact_info: {
        tel: '0988-206590',
        email: 'hmca.tw@gmail.com',
      },
      paymentErrorMsg: '',
      resHtml: '',
      formData: {
        order_uuid: '',
        pay_id: '',
        detail: [],
        return_url: '',
      },
    };
  },
  created() {
    if (this.$route.query && this.$route.query.uuid) {
      if (this.$cookies.get('applyPointInfo')) {
        this.applyID = this.$route.query.uuid;
        eventsApplyView({ uuid: this.applyID }).then((result) => {
          this.applyInfo = _.get(result, 'data.result.data', {});
          this.formData.order_uuid = _.get(result, 'data.result.data.order.uuid', '');
          this.formData.return_url = '/register/point/search/success';
        });
        orderPay(this.$cookies.get('applyPointInfo')).then((result) => {
          if (result.data.result.html) {
            this.resHtml = result.data.result.html;
          } else {
            this.paymentErrorMsg = result.data.message;
          }
        });
      } else {
        this.openModal({
          url: '/register/point/search',
          message: '請返回積分申請查詢頁選擇欲繳費項目',
        });
      }
    } else {
      this.openModal({
        url: '/register/point/search',
        message: '請返回積分申請查詢頁選擇欲繳費項目',
      });
    }
  },
  computed: {
    applyHost() {
      return _.get(this.applyInfo, 'apply_host', '');
    },
    applyTopic() {
      return _.get(this.applyInfo, 'name', '');
    },
    applyStart() {
      return _.get(this.applyInfo, 'start_date', '');
    },
    applyEnd() {
      return _.get(this.applyInfo, 'end_date', '');
    },
    applyHour() {
      return _.get(this.applyInfo, 'hours');
    },
    applyOrders() {
      return _.get(this.applyInfo, 'order.order_details', []);
    },
    applyAmount() {
      return _.get(this.applyInfo, 'order.total', '');
    },
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
  },
};
</script>
